@use "./config/" as *;
@forward "./main-scss/";

.watch-video {
    .embed-responsive {
        border-radius: var(--half-radius);
    }
}
@include mq(col-md) {
    .watch-video {
        .waf-body {
            margin-top: var(--space-6);
        }
    }
}
@include mq(col-lg) {
    .watch-video {
        .embed-responsive {
            width: 70%;
            margin-inline: auto;
        }
    }
}